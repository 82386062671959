.skills {
  margin-top: 7rem;
  border-radius: 1rem;
  margin-bottom: 6rem;
  position: relative;
  padding: 1.5rem;
  bottom: 2rem;
  background: #ffe4feb0;
  //   @media screen and (max-width: 767px) {
  //     background: #8000801c;
  //   }

  h2 {
    font-size: 1.5rem;
    position: relative;
    z-index: 3;
    width: fit-content;
    margin: 0.5rem;
    border-bottom: 2;
    text-align: -webkit-center;
    padding: 0 1rem;
    color: purple;
    background-color: rgb(255 255 255 / 82%);
    @media screen and (max-width: 767px) {
      background: #eb00da54;
    }
  }
  h3 {
    margin-left: 2rem;
    font-size: 1.5rem;
    position: relative;
    z-index: 3;
    width: fit-content;
    border-bottom: 2;
    text-align: -webkit-center;
    background: #eb00da1a;
    color: #d564d5;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  ul {
    margin-left: 2rem;
    display: inline-flex;
    flex-wrap: wrap;
  }

  li {
    color: purple;
    padding: 0.25rem;
    border-radius: 0.5rem;
    background: #0d16831c;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}
