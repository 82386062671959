.sidebar {
  position: fixed;
  left: 1vw;
  width: 20vw;
  top: 9vh;
  z-index: 3;

  @media screen and (max-width: 767px) {
    left: 0;
    top: 0.5rem;
    position: fixed;
    width: 15vw;
    margin-bottom: -24rem;
  }

  .hamburger {
    padding: 0;
    margin: auto;
    width: 100%;
  }

  .hamburger-box {
    position: relative;
    left: 0.2rem;
    top: -8.7rem;

    @media screen and (max-width: 767px) {
      left: 1rem;
      top: -6.4rem;
    }
  }

  .hamburger-inner {
    width: 35px;
    height: 1px;
    background: white !important;

    &:before {
      width: 35px;
      height: 1px;
      background: white !important;
    }

    &:after {
      width: 35px;
      height: 1px;
      background: white !important;
    }

    @media screen and (max-width: 767px) {
      width: 20px;
      height: 1px;

      &:before {
        width: 20px;
        height: 1px;
      }

      &:after {
        width: 20px;
        height: 1px;
      }
    }
  }

  .gameboy {
    height: 10rem;
    background: #b659b6;
    border-radius: 1rem;
    padding: 0.3rem;
    margin: 0 auto;
    display: block;

    @media screen and (max-width: 767px) {
      left: 4vw;
      height: 7rem;
    }
  }

  ul {
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  li {
    padding: 1rem;
    background: #d65fd6;
    border-radius: 1rem;
    color: white;
    margin: 1rem 0;
    font-size: 1vw;
    text-align: center;
    width: fit-content;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      font-size: 3.5vw;
    }
  }

  svg {
    stroke: #673ab7;
    left: -7vw;
    top: 2rem;
    position: absolute;
    z-index: -1;
  }
}
