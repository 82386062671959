html{ 
    font-size: 10px;/*for using REM units*/
 }
 body{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.3;
    color: #222;
 }
