@import url("https://fonts.googleapis.com/css2?family=Libre+Barcode+39+Text&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
.home {
  display: block;
  margin: auto;
  z-index: 1;
  width: 60%;
  max-width: 1280px;
  //   animation: appear 10s ease

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 0rem 2rem;
  }

  .inner {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -2px; /* !importanté */
      border-radius: inherit; /* !importanté */
      // background: linear-gradient(to right, red, orange);
    }
  }

  //   .hero {
  //     position: relative;
  //     width: 100%;
  //     height: 17rem;
  //     margin-top: 0rem;
  //     object-position: 0% 100%;
  //     object-fit: cover;
  //   }

  //   .backdrop-2 {
  //     content: "";
  //     bottom: 0;
  //     left: 0;
  //     position: fixed;
  //     width: 100%;
  //     height: 100vh;
  //     background: blue linear-gradient(rgb(57, 192, 255), purple);
  //     clip-path: polygon(50% 100%, 100% 0%, 100% 50%);
  //   }

  .carousel {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-around;
    margin-top: 3vh;
    height: 20vh;
    border-radius: 1.5rem;
    background: rgba(255, 238, 255, 0.33);

    .c-card {
      display: none;
    }

    .c-item {
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
      border-radius: 2rem;
      width: 10vh;
      height: 10vh;
      transition: 0.25s ease;
      z-index: 1;

      &:hover {
        .c-card {
          display: block;
        }
      }

      @media screen and (max-width: 767px) {
        width: 7vh;
        height: 7vh;
      }
    }
  }

  .carousel img {
    width: 100%;
    border-radius: 2rem;
  }

  .intro {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 3rem;
    // background: rgb(255, 208, 255);
    background: rgba(240, 227, 240, 0.33);
    border-radius: 1.5rem;
    color: purple;
    font-size: 1.5rem;
    padding: 1rem;
  }

  .backdrop {
    content: "";
    bottom: 0;
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    background: blue linear-gradient(rgb(57, 192, 255), rgb(199, 18, 199));
    clip-path: polygon(-20% 0%, 50% 150%, 120% 0%);
  }

  h1 {
    top: 1rem;
    color: #000000;
    // padding: 3rem;
    font-size: 1.2vw;
    position: relative;
    // top: -15rem;
    width: fit-content;
    border-radius: 2rem;
    z-index: 1;
    margin: auto;
    padding: 1rem 0;
    // margin-bottom: -10rem;
    font-family: sans-serif;
    letter-spacing: 1.5vw;

    @media screen and (max-width: 767px) {
      font-size: 5vw;
      letter-spacing: 3.5vw;
      padding: 0;
      margin: 3vh auto;
      margin-left: 11vw;
      margin-bottom: 5vh;
    }
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  }

  .birdbrain-gif {
    z-index: 1;
    position: absolute;
    top: 0.8vw;
    height: 3vw;
    left: 37.5vw;

    @media screen and (max-width: 767px) {
      z-index: 1;
      position: absolute;
      top: 3.5vh;
      height: 6vh;
      left: 18vw;
    }
  }

  h2 {
    font-size: 1.5rem;
    margin: 4vh 0rem;
    position: relative;
    z-index: 3;
    width: fit-content;
    border-bottom: 2;
    padding: 0.5rem;
    padding-left: 0rem;
    border-radius: 0.5rem;
    width: 100%;
    text-align: -webkit-center;
    background-color: rgba(255, 255, 255, 0.721);
    color: #d564d5;

    // @media screen and (max-width: 767px) {
    //   background: #eb00da26;
    // }

    // &::after {
    //   content: "";
    //   width: 90%;
    //   position: absolute;
    //   height: 1px;
    //   left: 10%;
    //   z-index: 1;
    //   top: 1vh;
    //   border-bottom: rgba(196, 91, 196, 0.5) 4px solid;

    //   @media screen and (max-width: 767px) {
    //     width: 80%;
    //     left: 20%;
    //   }
    // }
  }

  .projects {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    overflow-y: scroll;
    overflow-x: visible;
    flex-wrap: wrap;
    margin-bottom: -3rem;
    padding-bottom: 0rem;

    li {
      height: 20rem;
      width: 31%;
      display: flex;
      background: #ffffffa1;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      margin-bottom: 3rem;
      border-radius: 1rem;
      position: relative;
      transition: ease 0.25s;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
      /* box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 15%); */

      @media screen and (max-width: 767px) {
        width: 48%;
      }
    }

    li:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    }

    p {
      position: absolute;
      left: 0;
      padding: 1rem 2rem 1rem;
      bottom: 0;
      font-size: 1rem;
      border-radius: 0 0 1rem 1rem;
      background: #ffffff;
      width: 100%;
      transition: ease 0.25s;
    }
  }

  .reel {
    height: 20rem;
    width: 100%;
    object-fit: cover;
    margin: auto;
    border-radius: 1.2rem;

    &.pv {
      height: 20rem;
    }

    &.thyroid {
      background: white;
      //   display: flex;
      //   object-fit: fill;
      //   padding: 5rem;
      padding-top: 2rem;
      padding-bottom: 4rem;
    }
  }

  .robot {
    width: 70%;
    left: 10rem;
    margin: auto;
  }

  .manatee-wrapper {
    border-radius: 2rem;
    border: none;
    position: relative;
    height: 20rem;
    width: 300px;
    overflow: hidden;

    iframe {
      position: relative;
      right: 210px;
      top: -10px;
    }
  }

  iframe {
    border-radius: 2rem;
    border: none;
  }

  .tilt-test {
    position: absolute;
    content: "";
    display: flex;
    height: 3rem;
    top: 0;
    width: 3rem;
    background: rgba(144, 0, 255, 0.22);
    border-radius: 50%;
    z-index: 4;
  }

  * {
    transition: 1s ease;
    // transform: translate(0, 0) rotate(0);
  }

  .drop {
    // transform: translate(0, 100vh) rotate(360deg);
  }
}

.project-drawer {
  width: 40vw;
  margin-bottom: 6rem;

  @media screen and (max-width: 767px) {
    width: auto;
  }
  iframe {
    border: none;
    width: 40vw;
    height: 80vh;

    @media screen and (max-width: 767px) {
      width: 100%;
      height: 80vh;
    }
  }

  img,
  video {
    width: 100%;
    max-height: 56vh;

    @media screen and (max-width: 767px) {
      width: 100%;
      height: auto;
      max-height: none;
    }
  }

  h2 {
    font-size: 2.5rem;
    margin-top: -0.5rem;
    padding: 1rem;
    color: #7e0096;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }

  p {
    margin: 0 1rem;
    background: #fd00d21c;
    color: #8d0050;
    border-radius: 1rem;
    padding: 1rem;

    a {
      margin-top: 2rem;
      cursor: pointer;
      background: white;
      padding: 1rem;
      border-radius: 0.5rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.25s ease;

      &:hover {
        background: rgb(154, 68, 154);
        color: whitesmoke;
      }
    }

    img {
      max-height: 25rem;
      width: 37rem;
      display: flex;
      border-radius: 1rem;
      margin: 2rem 0;
    }

    video {
      width: 100%;
      border-radius: 1rem;
      margin: 2rem 0;
    }

    @media screen and (max-width: 767px) {
      img,
      video {
        width: auto;
        max-width: 100%;
      }
    }
  }

  .close-drawer {
    position: absolute;
    right: 0.5vw;
    top: 0.5vh;
    width: 4rem;
    height: 4rem;
    fill: #673ab7;
    transition: 0.5s ease;
    z-index: 4;

    &:hover {
      fill: #e37d9f;
    }

    svg {
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);

      &:hover {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      }
    }
  }
}

#delve {
  width: 40px;
}

// body {
//   background: black;
// }
