.c-card {
  position: relative;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  margin-top: 1rem;
  padding: 2rem;
  background: #fcfaff78;
  border-radius: 2rem;

  .wrapper {
    display: inline-flex;
  }

  img {
    height: 5rem;
    margin-right: 2rem;
  }

  .name {
    font-size: 4rem;
    margin-bottom: 1rem;
  }

  a {
    font-size: large;
    width: 50%;
    text-align: center;
    padding: 0.5rem;
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 1rem;
    background: #f1f1f1a2;
    position: relative;
    border-radius: 1rem;
    color: #d564d5;

    &:hover {
      color: #f1e0ff;
      background: #d564d5a2;
    }

    @media screen and (max-width: 767px) {
      margin-top: 22rem;
      position: relative;
      width: 90%;
    }
  }

  p {
    @media screen and (max-width: 767px) {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 7rem;
      padding: 2rem;
    }
  }
}
