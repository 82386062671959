.about {
  display: flex;
  position: relative;
  align-items: center;
  /* width: 16vw; */
  /* margin: auto; */
  opacity: 1;
  margin-top: 2rem;
  border-radius: 1rem;
  flex-flow: column;
  color: white;
  font-family: "Poiret One";
  padding: 1rem;
  font-size: 2rem;
  z-index: 1;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #ffffffb7;
  border: 1px solid #e8e8e8;

  @media screen and (max-width: 767px) {
    top: 0;
    left: 0;
    margin-top: 1vh;
    position: relative;
    width: 100%;
    padding: 1rem 2rem;
  }

  //   .gameboy {
  //     position: absolute;
  //     display: none;
  //     height: 32vh;
  //     background: #b659b6;
  //     border-radius: 1rem;
  //     padding: 0.3rem;
  //     margin-top: -1vh;
  //     z-index: -1;

  //     @media screen and (max-width: 767px) {
  //       display: block;
  //       height: auto;
  //       width: 100%;
  //     }
  //   }

  h2 {
    margin-bottom: 1rem;
    padding: 0 1rem;
    background-color: rgba(51, 51, 51, 0.37);
    border-radius: 1rem;
  }

  img {
    height: 14rem;
    position: relative;
    width: auto;
    margin-bottom: 0rem;

    @media screen and (max-width: 767px) {
      margin: 0;
      height: 21vh;
    }
  }

  p {
    padding: 0 1rem;
    border-radius: 1rem;
    font-size: 1.5rem;
    color: purple;
    margin-top: 2rem;
    width: 80%;
    text-align: center;

    @media screen and (max-width: 767px) {
      font-size: 2rem;
      font-weight: 600;
    }
  }

  ul {
    color: #333;
    // list-style-type: square;
    margin-top: 1rem;
    font-size: 1.8rem;

    @media screen and (max-width: 767px) {
      margin: 0;
      font-size: 1.75rem;
    }
  }

  .wrapper {
    display: inline-flex;
    justify-content: space-around;
    align-items: center;

    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
  }

  .social {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48%;
    margin-top: 3rem;
    margin-bottom: 1rem;
    padding: 0rem 2rem;

    @media screen and (max-width: 767px) {
      margin: 1rem 0rem;
    }

    a {
      display: flex;
      align-items: center;
    }

    img {
      height: 4rem;
      left: 0.4rem;
      transition: ease 0.2s;
      margin: 0;

      &:hover {
        height: 3.2rem;
      }
    }
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
